<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="accountForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="accountForm.description"
              trim
              placeholder="Description"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="type"
          >
            <b-form-group
              label="type "
              label-for="type"
            >
              <b-form-select
                id="type"
                v-model="accountForm.type"
                :options="optionstype"
                trim
                placeholder="Type"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Parent Id "
            label-for="Parent"
          >
            <b-form-select
              id="parent_id"
              v-model="accountForm.parent_id"
              :options="optionsMainAccounts"
              trim
              placeholder="Parent"
            />
          </b-form-group>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addAccount()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addAccount()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionstype: [
        { value: 'main', text: 'Main Account' },
        { value: 'sub', text: 'Sub Account' },
      ],
      optionsMainAccounts: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const accountForm = ref({})
    return {
      getValidationState,
      accountForm,
    }
  },
  mounted() {
    this.showAccount()
    this.getMainAccount()
  },
  methods: {

    showAccount() {
      if (this.$route.params.id) {
        axios.get(`accounting-account/${this.$route.params.id}`).then(res => {
          this.accountForm = decryptData(res.data.payload).data.account
        })
      } else {
        return false
      }
      return true
    },

    getMainAccount() {
      axios.get('accounting-account/main').then(res => {
        this.optionsMainAccounts = [
          { value: '', text: 'No Main Accounts' }, // Add the "No Main Acc" option first
          ...decryptData(res.data.payload).data.accounts.map(item => ({
            value: item.id,
            text: item.name,
          })),
        ]
      })
    },

    addAccount() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.accountForm) {
          formData.append(key, this.accountForm[key])
        }

        axios.post(`accounting-account/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'accounting' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.accountForm) {
          formData.append(key, this.accountForm[key])
        }

        axios.post('accounting-account', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'accounting' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    </style>
